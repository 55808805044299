<template>
    <div style="margin-top:160px"> 
 
        <div class="container mt-5">
            <div class="main row justify-content-center mt-3">
                <div class="input-group text-center margen">
                    <input @keyup="allText" v-model="searchtext" type="text" class="form-control" placeholder="Buscar..." style=" width: 50px !important;height: 70px !important;">
                    <div class="input-group-append">
                            <button @click="searchText()" class="btn btn-danger btn-block  " type="button" style="width: 100px; height: 70px;">
                                <i class="fa fa-search"></i>
                            </button>
                            <!-- <button class="btn btn-secondary " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style="width: 140px; height: 70px;">FILTRAR</button> 
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                             -->
                            <button @click="$bvModal.show('my-modal')" class="btn btn-secondary btn-block " type="button" style="width: 200px; height: 70px; margin-top:0px !important">AGREGAR NUEVO</button>
                            <button @click="$router.push('/home')" class="btn btn-secondary btn-block " type="button" style="width: 60px; height: 70px; margin-top:0px !important"><i class="fa fa-home"></i></button>
                    </div>
                </div>  
            </div>
        </div>
        <div class="container">
            <div class="row justify-content-center mt-5"><br>
                <div class="col-md-12 text-center">
                    <table class="table table-borderless ">
                        <thead class="table-secondary">
                            <tr>
                                <th scope="col">FECHA</th>
                                <th scope="col">NOMBRE Y APELLIDO</th>
                                <th scope="col">EDAD</th>
                                <th scope="col">ACCCION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row of rows" :key="row.id" >
                                <td>{{ row.birthday.substr(0,10) }}</td>
                                <td>{{ row.name  }}</td>
                                <td>{{ row.age + ' años' }}</td>
                                <td><b-button @click.prevent="editUser(row)"><i class="fa fa-edit"></i></b-button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
            
        <hr align = "center" width="75%" />
        <div class="container mt-5"><br>
            <h3 class="text-center">PROXIMOS 5 CUMPLEAÑEROS:</h3>
            <div class="row justify-content-center mt-3">
                <div class="col-md-2" v-for="item of lastFive" :key="item.id" >
                    <img :src="item.img!= 'null' && item.img!= null ? item.img : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCpawWq8L8kk4tzxZ6h-svcCsRbQFvUk2KfYnaBg7MOcqvhYdIJUL60i5pBI-f_whkQ90&usqp=CAU'" class="img-responsive rounded-pill borde" alt="...">
                    <div class="card-body">
                        <h5 style="height:50px" class="card-title">{{ item.name }}</h5>
                        <p class="card-text"><span style='text-decoration: underline'>&nbsp;{{ item.age + ' años' }} &nbsp;</span></p>
                        <span class="card-text">{{ item.birthday.substr(0,10) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="my-modal"  hide-footer title="NUEVO USUARIO">
            <add-users />
                <div class="row justify-content-center"> 
                    <div class="offset-md-8 col-md-2 mr-5"> 
                        <b-button @click="$bvModal.hide('my-modal')" class="btn btn-secondary btn-block mt-5" type="button" style="height: 70px;" >CANCELAR &nbsp;</b-button>
                    </div>
                </div>
        </b-modal>
        <b-modal id="editUser-modal"  hide-footer title="EDITAR USUARIO">
            <add-users :user="userSelected" />
                <div class="row justify-content-center"> 
                    <div class="offset-md-8 col-md-2 mr-5"> 
                        <b-button @click="$bvModal.hide('editUser-modal')" class="btn btn-secondary btn-block mt-5" type="button" style="height: 70px;" >CANCELAR &nbsp;</b-button>
                    </div>
                </div>
        </b-modal>        
    </div>
  
</template>

<script>
import addUsers from './addUsers.vue'

export default {
    components:{ addUsers },
    data(){
        return{
            rows:[ ],
            lastFive:[
                {id: 1 , date:'2001-01-02', img: 'https://i1.wp.com/wipy.tv/wp-content/uploads/2020/03/revelaron-el-cambio-de-look-de-wolverine.jpg?fit=1000%2C600&ssl=1', name: 'José', lastName: 'Cabrera', age: 20 },
                {id: 2 , date:'1995-05-19', img: 'http://yeux.com.mx/ColumnaUniversitaria/wp-content/uploads/2015/01/lctrtmd.jpg', name: 'Josh', lastName: 'Hakeman', age: 25 },
                {id: 3 , date:'1992-09-24', img: 'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/11-corte-pelo-mujer-cara-ovalada-melena-bob-1535533952.jpg?crop=1xw:1xh;center,top&resize=480:*', name: 'Rosse', lastName: 'Hamer', age: 28 },
                {id: 4 , date:'1990-12-12', img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRs5-xLz9vt_mBMy6meAqO9nf2w0U78eK8g-AavoM148KQ34kI&s', name: 'Peter', lastName: 'Gray', age: 30 },
                {id: 5 , date:'1988-10-28', img: 'https://lamenteesmaravillosa.com/wp-content/uploads/2018/06/jack-torrance-1.jpg', name: 'Sebastian', lastName: 'Blackwood', age: 42 },
            ],
            userSelected:{},
            rowsBase: null,
            searchtext: ''

        }
    },
    async mounted(){
    this.$store.watch(
      (state) => [state.userListRefresh].join(),
      () => {
        this.$bvModal.hide('editUser-modal');
        this.$bvModal.hide('my-modal');
        this.getData();
        this.getLastFive();
      }
    );        
        await this.getData();
        await this.getLastFive();

    },
    methods: {
        allText(){
            if(this.searchtext==''){
                this.rows = [...this.rowsBase]
            }
        },
        searchText(){
            this.rows = this.rows.filter(x => x.name.toUpperCase().includes(this.searchtext.toUpperCase()))
        },
        editUser(item){
            this.userSelected = item;
            console.log(item)
            this.$bvModal.show('editUser-modal');
        },
        getAge(dateString){
            let hoy = new Date()
            let fechaNacimiento = new Date(dateString)
            let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
            let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
            if ( diferenciaMeses < 0 || (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())) {
                edad--
            }
            return edad;
        },
        async getData(){
            let req = await fetch(this.$store.state.url + "getEmployees")
            let rows = await req.json()
            for await (let item of rows){
                item.age = this.getAge(item.birthday)
            }
            this.rows = rows
            this.rowsBase  = [...this.rows]
        },
        async getLastFive(){
            let req = await fetch(this.$store.state.url + "getLastFive")
            let lastFive = await req.json()
            for await (let item of lastFive){
                item.age = this.getAge(item.birthday)
            }
            this.lastFive = lastFive
        }
    }
}
</script>

<style>
.margen {
  margin-left: 2em;
}
.modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
}



</style>